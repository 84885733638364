import GeneralInfo from './GeneralInfo';
import OTPType from './OTPType';
import DigitalSignInfo from './DigitalSignInfo';
import SignType from './SignType';
import NationInfo from './NationInfo';
import AddressInfo from './AddressInfo';
import WorkingPosition from './WorkingPosition';
import Camera from './Camera';

export {
    GeneralInfo,
    OTPType,
    DigitalSignInfo,
    SignType,
    NationInfo,
    AddressInfo,
    WorkingPosition,
    Camera,
};
