import { useCallback, useEffect, RefObject } from 'react';

function useClickOutside<T extends HTMLElement>(refs: RefObject<T>[], onClickOutside: () => void) {
    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            for (let index = 0; index < refs.length; index++) {
                const ref = refs[index];

                if (ref.current && !ref.current.contains(event.target as Node)) {
                    onClickOutside();

                    return;
                }
            }
        },
        [refs, onClickOutside]
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, [refs, onClickOutside]);
}

export default useClickOutside;
