﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as SubDashboardState from '../../models/dashboard/SubDashboardComponent';

interface RequestSubDashboardComponentAction {
    type: 'REQUEST_SUB_DASHBOARD_COMPONENT';
}

interface ReceiveSubDashboardComponentAction {
    type: 'RECEIVE_SUB_DASHBOARD_COMPONENT';
    response?: SubDashboardState.ResponseGroup;
    errorMessage: string;
    isShowAlert: boolean;
}

interface ClearAlert {
    type: 'CLEAR_ALERT';
    errorMessage: string;
    isShowAlert: boolean;
}


type KnownAction = RequestSubDashboardComponentAction | ReceiveSubDashboardComponentAction | ClearAlert;

export const actionCreators = {
    requestGroup: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var errorMessage = '';
        var isShowAlert = false;

        if (localStorage.getItem('SSO_AUTH')) {
            fetch(`/v1/group`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                },
            })
                .then(response => response.json() as Promise<SubDashboardState.ResponseGroup>)
                .then(data => {
                   /* if (data && data != undefined && data.result_list != undefined) {
                        data.result_list.sort(function (a: any, b: any) {
                            var x = a.group_name.toLowerCase();
                            var y = b.group_name.toLowerCase();
                            if (x == y) {
                                return 0;
                            }

                            return x < y ? -1 : 1;
                        });
                    }*/

                    dispatch({ type: 'RECEIVE_SUB_DASHBOARD_COMPONENT', response: data ? data : undefined, errorMessage: errorMessage, isShowAlert: isShowAlert });
                })
                .then(error => {
                    console.log(error)
                });

            dispatch({ type: 'REQUEST_SUB_DASHBOARD_COMPONENT' });
        }
    },
    clearAlert: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_ALERT', errorMessage: '', isShowAlert: false });
    }
};

const unloadedState: SubDashboardState.SubDashboardState = { response: undefined, isLoading: false, errorMessage: "", isShowAlert: false };

export const reducer: Reducer<SubDashboardState.SubDashboardState> = (state: SubDashboardState.SubDashboardState | undefined, incomingAction: Action): SubDashboardState.SubDashboardState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_SUB_DASHBOARD_COMPONENT':
            return {
                response: state.response,
                isLoading: true,
                errorMessage: state.errorMessage,
                isShowAlert: state.isShowAlert
            };
        case 'RECEIVE_SUB_DASHBOARD_COMPONENT':
            return {
                response: action.response,
                isLoading: false,
                errorMessage: action.errorMessage,
                isShowAlert: action.isShowAlert
            };
        case 'CLEAR_ALERT':
            return {
                response: state.response,
                isLoading: false,
                errorMessage: action.errorMessage,
                isShowAlert: action.isShowAlert
            };

        default: return state;
    }
};