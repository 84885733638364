﻿import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/user/UserCompanyMaster'

// Load UserCompany
interface LoadUserCompanyMaster {
    type: 'LOAD_USERCOMPANYMASTER'
}
interface LoadedUserCompanyMaster {
    type: 'LOADEDUSERCOMPANYMASTER'
    response: Models.resGetUserCompanyMaster
    status: number,
    statusMessage: string
}
interface FailLoadedUserCompanyMaster {
    type: 'LOADUSERCOMPANYMASTERFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedUserCompanyMaster {
    type: 'CLEARLOADEDUSERCOMPANYMASTER'
}

type KnownAction = LoadUserCompanyMaster | LoadedUserCompanyMaster | FailLoadedUserCompanyMaster | ClearLoadedUserCompanyMaster

export const actionCreators = {
    // GET
    requestGetUserCompanyMaster: (check: boolean, method: string,citizen_id: string, page: string, page_size: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.UserCompanyMaster && check && method == 'GET') {
            console.log('call actionCreators : requestGetUserCompanyMaster')
            fetch(`/v1/company_master/user?citizen_id=` + citizen_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADUSERCOMPANYMASTERFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDUSERCOMPANYMASTER', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_USERCOMPANYMASTER' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDUSERCOMPANYMASTER' });
        }
    }
}

const unloadedStateGet: Models.UserCompanyMasterState = { isLoadGetUserCompanyMaster: false, statusGetUserCompanyMaster: 0 };

export const reducer: Reducer<Models.UserCompanyMasterState> = (state: Models.UserCompanyMasterState | undefined, incomingAction: Action): Models.UserCompanyMasterState => {
    if (state == undefined) {
        return unloadedStateGet;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'LOAD_USERCOMPANYMASTER':
            return {
                isLoadGetUserCompanyMaster: false,
                statusGetUserCompanyMaster: 0
            }

        case 'LOADEDUSERCOMPANYMASTER':
            return {
                isLoadGetUserCompanyMaster: true,
                responseGetUserCompanyMaster: action.response as any,
                statusGetUserCompanyMaster: action.status
            }

        case 'LOADUSERCOMPANYMASTERFAIL':
            return {
                isLoadGetUserCompanyMaster: true,
                messageGetUserCompanyMaster: action.statusMessage,
                statusGetUserCompanyMaster: action.status
            }

        case 'CLEARLOADEDUSERCOMPANYMASTER':
            return {
                isLoadGetUserCompanyMaster: false,
                messageGetUserCompanyMaster: "",
                statusGetUserCompanyMaster: 0
            }
        default: return state;
    }
}