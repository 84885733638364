import { FormEvent, memo, useState } from 'react';
import DatePicker from 'react-datepicker';

import { Button, Input, Label } from 'common/materials';

export type SearchValues = {
    citizenId?: string;
    from?: Date | null;
    to?: Date | null;
};

type Props = {
    onSubmitSearch: (values: SearchValues) => void;
};

function SearchPane(props: Props) {
    const { onSubmitSearch } = props;

    const [citizenId, setCitizenId] = useState<string>();
    const [from, setFrom] = useState<Date | null>();
    const [to, setTo] = useState<Date | null>();

    const handleSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmitSearch({ citizenId: citizenId?.trim(), from, to });
    };

    return (
        <form
            onSubmit={handleSubmitSearch}
            className="flex flex-wrap items-center gap-4 py-2"
        >
            <div className="flex gap-2">
                <div className="flex flex-1 flex-col">
                    <Label>Citizen ID</Label>
                    <Input
                        value={citizenId}
                        onChange={event => setCitizenId(event.target.value)}
                    />
                </div>
                <div className="flex flex-1 flex-col">
                    <Label>From</Label>
                    <DatePicker
                        selectsStart
                        selected={from}
                        startDate={from}
                        endDate={to}
                        onChange={setFrom}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={5}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy HH:mm"
                        customInput={<Input />}
                    />
                </div>
                <div className="flex flex-1 flex-col">
                    <Label>To</Label>
                    <DatePicker
                        selectsEnd
                        selected={to}
                        startDate={from}
                        endDate={to}
                        minDate={from}
                        onChange={setTo}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={5}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy HH:mm"
                        customInput={<Input />}
                    />
                </div>
            </div>

            <Button
                type="submit"
                className="self-end bg-primary-900 text-white"
            >
                Search
            </Button>
        </form>
    );
}

export default memo(SearchPane);
