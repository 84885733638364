﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as PlatformModel from '../../models/group/Platform';

interface RequestFunctionAction {
    type: 'REQUEST_PLATFORM';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_PLATFORM';
    model: PlatformModel.ResponsePlatform;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (pageload: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.platform && pageload == true) {

            fetch(`/v1/platform`, {
                method: 'GET'
            })
                .then(response => response.json() as Promise<PlatformModel.ResponsePlatform>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_PLATFORM', model: data });
                })
                .then(error => {

                });

            dispatch({ type: 'REQUEST_PLATFORM' });
        }
    }
};

const unloadedState: PlatformModel.PlatformState = { isLoading: false, response: undefined };

export const reducer: Reducer<PlatformModel.PlatformState> = (state: PlatformModel.PlatformState | undefined, incomingAction: Action): PlatformModel.PlatformState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_PLATFORM':
            return {
                isLoading: false
            };
        case 'RECEIVE_PLATFORM':
            return {
                isLoading: true,
                response: action.model
            };

        default: return state;
    }
};