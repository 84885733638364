﻿import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/dashboard/SubDashboardComponentV2'

// Load UserCompany
interface LoadSubDashboardComponentV2 {
    type: 'LOAD_SUBDASHBOARDCOMPONENTV2'
}
interface LoadedSubDashboardComponentV2 {
    type: 'LOADEDSUBDASHBOARDCOMPONENTV2'
    response: Models.resGetSubDashboardComponentV2
    status: number,
    statusMessage: string
}
interface FailLoadedSubDashboardComponentV2 {
    type: 'LOADSUBDASHBOARDCOMPONENTV2FAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedSubDashboardComponentV2 {
    type: 'CLEARLOADEDSUBDASHBOARDCOMPONENTV2'
}

type KnownAction = LoadSubDashboardComponentV2 | LoadedSubDashboardComponentV2 | FailLoadedSubDashboardComponentV2 | ClearLoadedSubDashboardComponentV2

export const actionCreators = {
    // GET
    requestGetSubDashboardComponentV2: (check: boolean, method: string, citizen_id: string, page: string, page_size: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.SubDashboardComponentV2 && check && method == 'GET') {
            console.log('call actionCreators : requestGetSubDashboardComponentV2')
            fetch(`/v1/company/application_mapping_menu?citizen_id=` + citizen_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADSUBDASHBOARDCOMPONENTV2FAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'LOADEDSUBDASHBOARDCOMPONENTV2', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_SUBDASHBOARDCOMPONENTV2' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDSUBDASHBOARDCOMPONENTV2' });
        }
    }
}

const unloadedStateGet: Models.SubDashboardComponentV2State = { isLoadGetSubDashboardComponentV2: false, statusGetSubDashboardComponentV2: 0 };

export const reducer: Reducer<Models.SubDashboardComponentV2State> = (state: Models.SubDashboardComponentV2State | undefined, incomingAction: Action): Models.SubDashboardComponentV2State => {
    if (state == undefined) {
        return unloadedStateGet;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'LOAD_SUBDASHBOARDCOMPONENTV2':
            return {
                isLoadGetSubDashboardComponentV2: false,
                statusGetSubDashboardComponentV2: 0
            }

        case 'LOADEDSUBDASHBOARDCOMPONENTV2':
            return {
                isLoadGetSubDashboardComponentV2: true,
                responseGetSubDashboardComponentV2: action.response as any,
                statusGetSubDashboardComponentV2: action.status
            }

        case 'LOADSUBDASHBOARDCOMPONENTV2FAIL':
            return {
                isLoadGetSubDashboardComponentV2: true,
                responseGetSubDashboardComponentV2: action.statusMessage,
                statusGetSubDashboardComponentV2: action.status
            }

        case 'CLEARLOADEDSUBDASHBOARDCOMPONENTV2':
            return {
                isLoadGetSubDashboardComponentV2: false,
                messageGetSubDashboardComponentV2: "",
                statusGetSubDashboardComponentV2: 0
            }
        default: return state;
    }
}