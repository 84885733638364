﻿import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/platform/ConfirmStatusApplication';
import * as Models from '../../models/platform/ConfirmStatusApplication';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

type Store = Models.ConfirmApplicationState & typeof Stores.actionCreators;

class ModalConfirmStatusApplicationComponent extends React.PureComponent<any, { pageLoad: boolean, isOpen: boolean, isRegisClick: boolean, isOpenAlert: boolean, isAction: boolean, classSpinner: string }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            isOpen: false,
            isRegisClick: false,
            isOpenAlert: false,
            isAction: false,
            classSpinner: "d-none"
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.acceptClick = this.acceptClick.bind(this);
        this.rejectClick = this.rejectClick.bind(this);

    }

    private toggleModal() {
        this.setState({
            isOpen: !this.state.isOpen,
            classSpinner: "d-none"
        });

        if (!this.state.isOpen) {
            this.props.afterCloseModal();
        }
    }

    private acceptClick() {
        this.props.requestFunction(this.state.pageLoad, this.props.appUUID, "PUT");

        this.setState({
            isAction: true,
            classSpinner: "d-inline-block"
        });
    }

    private rejectClick() {
        this.props.requestFunction(this.state.pageLoad, this.props.appUUID, "DELETE");

        this.setState({
            isAction: true,
            classSpinner: "d-inline-block"
        });
    }

    private togglAlert() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {

        if (this.props.isOpenModal) {
            this.toggleModal();
            this.props.afterOpenModal();
        }

        if (this.props.isLoading && this.state.isAction) {
            if (this.props.message == 200) {
                console.log("success");
                this.toggleModal();
                this.props.BindData();
                this.setState({
                    isAction: false
                });
            } else {
                console.log("fail");
                this.setState({
                    isAction: false
                });
            }
        }

        return (
            <div>
                <Modal isOpen={this.state.isOpen}>
                    <ModalHeader toggle={this.toggleModal}>Confirm {this.props.typeClick}</ModalHeader>
                    <ModalBody>
                        <p style={{ fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif" }}>Are you sure to {this.props.typeClick} App [ {this.props.appName} ]?</p>
                    </ModalBody>
                    <ModalFooter>
                        {this.props.typeClick == "Accept" ?
                            <Button color="primary" onClick={this.acceptClick}>
                                <Spinner color="primary" className={this.state.classSpinner} style={{ width: '20px', height: '20px' }} />
                                {' '}Accept
                            </Button>
                            :
                            <Button color="danger" onClick={this.rejectClick}>
                                <Spinner color="danger" className={this.state.classSpinner} style={{ width: '20px', height: '20px' }} />
                                {' '}Reject
                            </Button>
                        }
                        <Button color="secondary" onClick={this.toggleModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.confirmStatusApplication,
    Stores.actionCreators
)(ModalConfirmStatusApplicationComponent);