/**
 * @description This component is only use under `UserRoleMappingModal`.
 */

// ! This page is type tricky, play wisely

import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Checkbox,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { twMerge } from 'tailwind-merge';

import { nameof } from 'common/helpers';
import { Button, Input, Label } from 'common/materials';
import { RoleProfileMapping } from 'models/role/RoleProfileMapping';
import {
    ProfileObjectMapping,
    ProfileObjectMappingRequest
} from 'models/role/ProfileObjectMapping';
import { MainProgram } from 'models/role/MainProgram';

import RoleList from './RoleList';
import MoveInteractionBar from './MoveInteractionBar';
import { getRoleLabel } from './UserRoleMappingModal';
import ManageActivityAndPlantModal, {
    ManageActivityAndPlantModalRefHandle
} from './ManageActivityAndPlantModal';

export function getMainProgramLabel(program: MainProgram | ProfileObjectMapping) {
    return program.parentobjectid + ((program.description && ` - ${program.description}`) ?? '');
}

const DEFAULT_CHANGED_ROLE = {
    roleid: '',
    rolename: '',
    profileid: '',
    description: ''
} as RoleProfileMapping;

type Props = {
    mode?: 'add' | 'edit';
    isOpen: boolean;
    selectedRole?: RoleProfileMapping;
    mainPrograms: MainProgram[];
    profileObjectMappings: ProfileObjectMapping[];
    onClose: () => void;
    onClickSave: (
        objectList: ProfileObjectMappingRequest['object_list'],
        changedRole?: RoleProfileMapping
    ) => void;
};

function RoleObjectMappingModal(props: Props) {
    const {
        mode,
        isOpen,
        selectedRole,
        mainPrograms,
        profileObjectMappings,
        onClose,
        onClickSave
    } = props;

    // Filter programs that's not in user selected profiles (Compute once when open this modal)
    const remaining = useMemo(() => {
        const profileIdMaps = new Set(
            profileObjectMappings.map(profile => profile.parentobjectid + profile.objectid)
        );

        return mainPrograms.filter(
            program => !profileIdMaps.has(program.parentobjectid + program.objectid)
        );
    }, [mainPrograms, profileObjectMappings]);

    const [changedRole, setChangedRole] = useState<RoleProfileMapping>();
    const [remainingPrograms, setRemainingPrograms] = useState<MainProgram[]>([]);
    const [userSelectedProfiles, setUserSelectedProfiles] = useState<ProfileObjectMapping[]>([]);

    // Track checked roles
    const [selectedPrograms, setSelectedPrograms] = useState<
        Set<MainProgram | ProfileObjectMapping>
    >(new Set());
    const [searchMainProgram, setSearchMainProgram] = useState('');
    const deferredSearchMainProgram = useDeferredValue(searchMainProgram);

    const [onlyCategory, setOnlyCategory] = useState<'FWEB' | 'FGUI'>();

    const filteredRemainingPrograms = useMemo(() => {
        const searchTerm = deferredSearchMainProgram.toLowerCase();

        return remainingPrograms.filter(program => {
            const label = getMainProgramLabel(program);
            const only =
                onlyCategory === 'FWEB'
                    ? label.startsWith('fweb_')
                    : onlyCategory === 'FGUI'
                    ? !label.startsWith('fweb_')
                    : true;

            return label.toLowerCase().includes(searchTerm) && only;
        });
    }, [deferredSearchMainProgram, remainingPrograms, onlyCategory]);

    // Use for open `ManageActivityAndPlantModal`
    const manageActivityAndPlantModalRef = useRef<ManageActivityAndPlantModalRefHandle>(null);
    const [selectedProgramDetail, setSelectedProgramDetail] = useState<
        MainProgram | ProfileObjectMapping
    >();
    const showManageActivityAndPlantModal = !!selectedProgramDetail;

    useEffect(() => {
        if (!isOpen) {
            setChangedRole(undefined);
            setRemainingPrograms([]);
            setUserSelectedProfiles([]);

            return;
        }

        setChangedRole(mode === 'add' ? DEFAULT_CHANGED_ROLE : selectedRole);
        setRemainingPrograms(remaining);
        setUserSelectedProfiles(profileObjectMappings);
        setSelectedPrograms(new Set());
    }, [isOpen, remaining, mainPrograms, profileObjectMappings, selectedRole]);

    const handleChangeRoleDetail = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setChangedRole(prevState =>
                !prevState
                    ? undefined
                    : {
                          ...prevState,
                          [event.target.name]: event.target.value
                      }
            );
        },
        [setChangedRole]
    );

    const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchMainProgram(event.target.value);
    }, []);

    const handleSelectRole = useCallback(
        (role: MainProgram | ProfileObjectMapping) => {
            if (!selectedPrograms.has(role)) {
                selectedPrograms.add(role);
            } else {
                selectedPrograms.delete(role);
            }

            setSelectedPrograms(new Set(selectedPrograms));
        },
        [selectedPrograms]
    );

    const handleMove = useCallback(
        (to: 'left' | 'right') => {
            const [methodA, methodB] = to === 'left' ? ['add', 'delete'] : ['delete', 'add'];

            const remainingProgramsSet = new Set(remainingPrograms);
            const userSelectedProfilesSet = new Set(userSelectedProfiles);

            selectedPrograms.forEach(selectedProgram => {
                remainingProgramsSet[methodA](selectedProgram);
                userSelectedProfilesSet[methodB](selectedProgram);
            });
            selectedPrograms.clear();

            setRemainingPrograms(
                sortRolesByLabel(Array.from(remainingProgramsSet) as ProfileObjectMapping[])
            );
            setUserSelectedProfiles(sortRolesByLabel(Array.from(userSelectedProfilesSet)));
            setSelectedPrograms(new Set(selectedPrograms));
        },
        [remainingPrograms, userSelectedProfiles, selectedPrograms]
    );

    const handleMoveAll = useCallback(
        (to: 'left' | 'right') => {
            const [setterA, setterB] =
                to === 'left'
                    ? [setUserSelectedProfiles, setRemainingPrograms]
                    : [setRemainingPrograms, setUserSelectedProfiles];

            setterA([]);
            setterB(
                sortRolesByLabel(
                    userSelectedProfiles.concat(remainingPrograms as ProfileObjectMapping[])
                )
            );
        },
        [remainingPrograms, userSelectedProfiles]
    );

    const handleClickDetail = useCallback((profile: ProfileObjectMapping) => {
        setSelectedProgramDetail(profile);
    }, []);

    const handleCloseManageActivityAndPlantModal = useCallback(() => {
        setSelectedProgramDetail(undefined);
    }, []);

    const handleClickSave = useCallback(() => {
        const changedProgramsMap =
            manageActivityAndPlantModalRef.current?.getCurrentChangedProgramsMap();

        const objectList = userSelectedProfiles.map<
            ProfileObjectMappingRequest['object_list'][number]
        >(profile => {
            const changedProgram = changedProgramsMap?.get(
                profile.parentobjectid + profile.objectid
            );
            return {
                parent_object_id: profile.parentobjectid,
                object_id: profile.objectid,
                object_type: profile.objecttype,
                object_value: changedProgram
                    ? changedProgram.objectvalue
                    : profile.objectvalue ?? '*'
            };
        });

        // Assign `profileid` same value as `roleid` (for while)
        const clonedChangedRole = { ...changedRole } as RoleProfileMapping;
        clonedChangedRole.profileid = clonedChangedRole.roleid;

        onClickSave(objectList, clonedChangedRole);
    }, [userSelectedProfiles, changedRole, onClickSave]);

    // * PRIVATE
    const sortRolesByLabel = (roles: ProfileObjectMapping[]) => {
        return roles.sort((a, b) => {
            const labelA = a.parentobjectid + a.description;
            const labelB = b.parentobjectid + b.description;

            return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            {(selectedRole || mode) && (
                <ModalContent
                    className={twMerge(
                        'max-w-[95dvw] transition-transform',
                        showManageActivityAndPlantModal && 'translate-y-8 scale-95'
                    )}
                >
                    <ModalHeader>
                        {mode === 'add' ? (
                            <p>Add Role Object</p>
                        ) : (
                            <p>
                                Manage Role Object -{' '}
                                <span className="text-primary-900">
                                    {selectedRole && getRoleLabel(selectedRole)}
                                </span>
                            </p>
                        )}
                    </ModalHeader>
                    <ModalBody className="flex flex-1 flex-col p-6 pt-0">
                        <div className="flex w-full flex-col gap-2 md:w-1/2">
                            <Accordion
                                allowToggle
                                defaultIndex={mode === 'add' ? 0 : undefined}
                            >
                                <AccordionItem>
                                    <AccordionButton className="px-0">
                                        <div className="flex-1 text-left font-semibold text-primary-900">
                                            Role Detail
                                        </div>
                                        <AccordionIcon className="text-primary-900" />
                                    </AccordionButton>
                                    <AccordionPanel className="space-y-2">
                                        <div className="flex gap-2">
                                            <div className="flex-1">
                                                <Label>Role ID : </Label>
                                                <Input
                                                    disabled={mode === 'edit'}
                                                    maxLength={10}
                                                    name={nameof('roleid', changedRole)}
                                                    placeholder="Role ID"
                                                    value={changedRole?.roleid}
                                                    onChange={
                                                        mode === 'add'
                                                            ? handleChangeRoleDetail
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                            <div className="flex-1">
                                                <Label>Role name : </Label>
                                                <Input
                                                    name={nameof('rolename', changedRole)}
                                                    placeholder="Role name"
                                                    value={changedRole?.rolename}
                                                    onChange={handleChangeRoleDetail}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex gap-2">
                                            {/* <div className="flex-1">
                                                <Label>Profile ID : </Label>
                                                <Input
                                                    disabled={mode === 'edit'}
                                                    maxLength={10}
                                                    name={nameof('profileid', changedRole)}
                                                    placeholder="Profile ID"
                                                    value={changedRole?.profileid}
                                                    onChange={
                                                        mode === 'add'
                                                            ? handleChangeRoleDetail
                                                            : undefined
                                                    }
                                                />
                                            </div> */}
                                            <div className="flex-1">
                                                <Label>Description : </Label>
                                                <Input
                                                    name={nameof('description', changedRole)}
                                                    placeholder="Description"
                                                    value={changedRole?.description}
                                                    onChange={handleChangeRoleDetail}
                                                />
                                            </div>
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>

                        <div className="mt-4 flex w-1/2 flex-col">
                            <Label>Find Main Program : </Label>
                            <Input
                                className="w-full"
                                placeholder="Search ..."
                                value={searchMainProgram}
                                onChange={handleChangeSearch}
                            />
                        </div>

                        <p className="mt-6 font-medium text-neutral-600">Select Parent Object</p>

                        <div className="mt-1 flex gap-4">
                            <span className="font-medium text-neutral-600">Only</span>
                            <Checkbox
                                colorScheme="primary"
                                isChecked={onlyCategory === 'FWEB'}
                                onChange={() => setOnlyCategory('FWEB')}
                            >
                                FWEB
                            </Checkbox>
                            <Checkbox
                                colorScheme="primary"
                                isChecked={onlyCategory === 'FGUI'}
                                onChange={() => setOnlyCategory('FGUI')}
                            >
                                FGUI
                            </Checkbox>
                        </div>

                        <div className="mt-3 flex flex-1 gap-4">
                            {/* Available roles */}
                            <RoleList<MainProgram>
                                noAddButton
                                noDeleteButton
                                noDetailButton
                                title="Main Program List"
                                roles={filteredRemainingPrograms}
                                selectedRoles={selectedPrograms}
                                getRoleLabel={getMainProgramLabel}
                                onSelectRole={handleSelectRole}
                            />

                            {/* Interactions */}
                            <MoveInteractionBar
                                onMoveSelectedToRight={() => handleMove('right')}
                                onMoveSelectedToLeft={() => handleMove('left')}
                                onMoveToRightAll={() => handleMoveAll('right')}
                                onMoveToLeftAll={() => handleMoveAll('left')}
                            />

                            {/* User selected */}
                            <RoleList<ProfileObjectMapping>
                                noAddButton
                                noDeleteButton
                                title="Selected Main Program"
                                roles={userSelectedProfiles}
                                selectedRoles={selectedPrograms as Set<ProfileObjectMapping>}
                                getRoleLabel={getMainProgramLabel}
                                onSelectRole={handleSelectRole}
                                onClickRoleDetail={handleClickDetail}
                            />
                        </div>

                        <ManageActivityAndPlantModal
                            ref={manageActivityAndPlantModalRef}
                            isOpen={showManageActivityAndPlantModal}
                            selectedProgram={selectedProgramDetail}
                            onClose={handleCloseManageActivityAndPlantModal}
                        />
                    </ModalBody>
                    <ModalFooter className="gap-2">
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            onClick={handleClickSave}
                            className="bg-primary-900 text-white"
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            )}
        </Modal>
    );
}

export default RoleObjectMappingModal;
