import 'react-datepicker/dist/react-datepicker.css';

import { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';

import { Label, Select } from 'common/materials';
import { ResponseListWithPaging } from 'models';
import { AuthenticationHistory } from 'models/history/AuthenticationHistory';
import { Pagination } from 'common/components';

import { AuthenticationHistoryTable, SearchPane, SearchValues } from './components';

function AuthenticationHistoryLogs() {
    const toast = useToast();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    // Search
    const [citizenId, setCitizenId] = useState<string>();
    const [from, setFrom] = useState<Date | null>();
    const [to, setTo] = useState<Date | null>();

    const fromISO = useMemo(() => (!from ? undefined : from.toISOString()), [from]);
    const toISO = useMemo(() => (!to ? undefined : to.toISOString()), [to]);

    const { isPending, error, data, isPlaceholderData } = useQuery({
        queryKey: ['auth-history', { page, pageSize, citizenId, fromISO, toISO }],
        queryFn: () =>
            axios.get<ResponseListWithPaging<AuthenticationHistory>>('/v1/auth-history', {
                params: {
                    page,
                    page_size: pageSize,
                    citizen_id: citizenId,
                    from_date: fromISO,
                    to_date: toISO
                }
            }),
        select: response => response.data.result_list,
        placeholderData: keepPreviousData
    });

    // Error handling
    useEffect(() => {
        if (!error) return;

        if (error instanceof AxiosError) {
            toast({
                title: 'Error',
                description: error.response?.data.message || 'ไม่ทราบสาเหตุ'
            });
        } else if (error instanceof Error) {
            toast({
                title: 'Error',
                description: error.message
            });
        }
    }, [error]);

    const handleSubmitSearch = useCallback((values: SearchValues) => {
        const { citizenId, from, to } = values;

        setPage(1);

        setFrom(from);
        setTo(to);
        setCitizenId(citizenId);
    }, []);

    return (
        <div className="flex flex-col gap-y-2.5 px-3">
            <p className="text-2xl font-bold">Authentication History</p>

            <SearchPane onSubmitSearch={handleSubmitSearch} />

            <div className="flex items-center justify-end gap-4">
                <div className="flex w-[200px] items-center gap-2">
                    <Label>Page size</Label>
                    <Select
                        options={[
                            { label: 25, value: 25 },
                            { label: 50, value: 50 },
                            { label: 100, value: 100 }
                        ]}
                        value={{ label: pageSize, value: pageSize }}
                        onChange={newValue => newValue && setPageSize(newValue.value)}
                    />
                </div>

                <Pagination
                    currentPage={page}
                    handleClickFirstPage={() => setPage(1)}
                    handleClickNextPage={() => !isPlaceholderData && setPage(page + 1)}
                    handleClickPreviousPage={() => setPage(Math.max(page - 1, 1))}
                    className="m-0 items-end px-0"
                />
            </div>

            <AuthenticationHistoryTable
                isPending={isPending}
                authHistory={data}
            />

            <Pagination
                currentPage={page}
                handleClickFirstPage={() => setPage(1)}
                handleClickNextPage={() => data && data.length !== 0 && setPage(page + 1)}
                handleClickPreviousPage={() => page !== 1 && setPage(page - 1)}
                className="my-2 ml-auto px-0"
            />
        </div>
    );
}

export default AuthenticationHistoryLogs;
