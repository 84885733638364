﻿import { makeAutoObservable, runInAction } from 'mobx';

import RootStore from '../Root.store';
import { getClientSystemProgram } from '../../data/clientSystem/repositories/clientSystemProgramRepository';
import { ClientSystemProgram } from '../../models/clientSystem/clienySystemProgram';

class ClientSystemStore {
    private _clientSystemProgram: ClientSystemProgram[] = [];

    public get clientSystemProgram() {
        return this._clientSystemProgram;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchClientSystemProgram(sid: string) {
        const [error, responseClientSystemProgram] = await getClientSystemProgram({
            sid
        });

        if (error) {
            throw error;
        }

        runInAction(() => {
            this._clientSystemProgram = responseClientSystemProgram.result_list;
        });
    }
}

export default ClientSystemStore;