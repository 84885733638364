﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as PlatformModel from '../../models/group/Platform';

interface RequestFunctionAction {
    type: 'REQUEST_PLATFORM';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_PLATFORM';
    model: PlatformModel.ResponsePlatform;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (platformId: string, platformName: string, createBy: string, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log("start call api");

        if (appState && appState.platformManage && method == "POST") {

            const body = {
                'platform_name': platformName,
                'create_by': createBy
            }

            fetch(`/v1/platform`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then(response => {
                    return response.json() as Promise<PlatformModel.ResponsePlatform>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PLATFORM', model: data });
                })
                .catch(error => {

                });

            dispatch({ type: 'REQUEST_PLATFORM' });
        }

        if (appState && appState.platformManage && method == "DELETE") {

            fetch(`/v1/platform/` + platformId, {
                method: 'DELETE'
            })
                .then(response => {
                    return response.json() as Promise<PlatformModel.ResponsePlatform>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PLATFORM', model: data });
                })
                .catch(error => {

                });

            dispatch({ type: 'REQUEST_PLATFORM' });
        }
    }
};

const unloadedState: PlatformModel.PlatformState = { isLoading: false, response: undefined };

export const reducer: Reducer<PlatformModel.PlatformState> = (state: PlatformModel.PlatformState | undefined, incomingAction: Action): PlatformModel.PlatformState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_PLATFORM':
            return {
                isLoading: false
            };
        case 'RECEIVE_PLATFORM':
            return {
                isLoading: true,
                response: action.model
            };

        default: return state;
    }
};