﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as CompanyModel from '../../models/company/Company';

interface RequestFunctionAction {
    type: 'REQUEST_COMPANY';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_COMPANY';
    model: CompanyModel.ResponseCompany;
}

interface ReceiveCompanyMaster {
    type: 'RECEIVE_COMPANY_MASTER';
    model: CompanyModel.ResponseCompanyMaster;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ReceiveCompanyMaster

export const actionCreators = {
    requestFunction: (pageload: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.application && pageload == true) {

            fetch(`/v1/company`, {
                method: 'GET'
            })
                .then(response => response.json() as Promise<CompanyModel.ResponseCompany>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_COMPANY', model: data });
                })
                .then(error => {

                });

            dispatch({ type: 'REQUEST_COMPANY' });
        }
    },

    requestGetCompanyMaster: (pageload: boolean, sid: string, status: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.application && pageload) {
            fetch(`/v1/company_master?sid=` + sid + `&status=` + status, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH')
                }
            })
                .then(response => response.json() as Promise<CompanyModel.ResponseCompanyMaster>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_COMPANY_MASTER', model: data });
                })
                .catch(error => {

                });

            dispatch({ type: 'REQUEST_COMPANY' });
        }
    }
};

const unloadedState: CompanyModel.CompanyState = { isLoading: false, response: undefined, version: 0 };

export const reducer: Reducer<CompanyModel.CompanyState> = (state: CompanyModel.CompanyState | undefined, incomingAction: Action): CompanyModel.CompanyState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_COMPANY':
            return {
                isLoading: false,
                version: 0
            };
        case 'RECEIVE_COMPANY':
            return {
                isLoading: true,
                response: action.model,
                version: 0
            };
        case 'RECEIVE_COMPANY_MASTER':
            return {
                isLoading: true,
                responseCompanyMaster: action.model,
                version: 0
            };
        default: return state;
    }
};